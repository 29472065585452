import { TableListItemProps } from "./TableListItem";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import { TableListBulkCheckbox } from "./TableListBulkCheckbox";
import { Dropdown } from "@casasoft/styleguide/components/dropdown";
import { DragHandle } from "./TableListItemHelpers";

export interface TableListItemCol {
  key: string;
  align?: string;
  cardFlex?: string;
  width?: number | string;
  baseWidth?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  label?: string;
  isSortable?: boolean;
  placeholder?: string;
  flex?: string;
}

interface TableListItemColsProps
  extends Pick<
    TableListItemProps,
    | "item"
    | "cols"
    | "bulkSelectControl"
    | "itemActionNodes"
    | "renderValue"
    | "sortable"
    | "mini"
    | "itemIndex"
    | "cardView"
  > {}

function TableListItemCols({
  item,
  cols = [],
  bulkSelectControl,
  itemActionNodes,
  renderValue = (singleItem, key) => {
    return singleItem[key];
  },
  sortable,
  mini,
  itemIndex,
  cardView,
}: TableListItemColsProps) {
  const itemActionsList =
    itemActionNodes && !item.isFiller && !sortable
      ? itemActionNodes(item) || []
      : [];

  return (
    <>
      {!!bulkSelectControl && (
        <TableListBulkCheckbox
          item={item}
          checked={bulkSelectControl.bulkSelected.includes(item.id)}
          onChange={(itemChanged) => {
            bulkSelectControl.toggleItem(itemChanged);
          }}
          disabled={item.item_inactive_blocking === "checkbox"}
        />
      )}
      {cols.map((col) => {
        const mutadedItem = item;
        if (mutadedItem.isFiller && !mutadedItem[col.key]) {
          if (col.placeholder && col.placeholder === "circle") {
            mutadedItem[col.key] = (
              <span className="tw-inline-block tw-cs-shimmer-item tw-rounded-full tw-w-4 tw-leading-4 ">
                &nbsp;
              </span>
            );
          } else if (col.placeholder && col.placeholder === "circle2x") {
            mutadedItem[col.key] = (
              <span className="tw-inline-block tw-cs-shimmer-item tw-rounded-full tw-w-8 tw-leading-8">
                &nbsp;
              </span>
            );
          } else if (col.placeholder && col.placeholder === "circle3x") {
            mutadedItem[col.key] = (
              <span className="tw-inline-block tw-cs-shimmer-item tw-rounded-full tw-w-12 tw-leading-[3rem]">
                &nbsp;
              </span>
            );
          } else if (col.placeholder && col.placeholder === "line4x") {
            mutadedItem[col.key] = (
              <span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
              </span>
            );
          } else if (col.placeholder && col.placeholder === "line3x") {
            mutadedItem[col.key] = (
              <span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
              </span>
            );
          } else if (col.placeholder && col.placeholder === "line2x") {
            mutadedItem[col.key] = (
              <span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
                <span className="tw-inline-block tw-cs-shimmer-item tw-w-full tw-scale-90">
                  &nbsp;
                </span>
              </span>
            );
          } else {
            mutadedItem[col.key] = (
              <span className="tw-inline-block tw-cs-shimmer-item tw-w-full">
                &nbsp;
              </span>
            );
          }
        }
        return (
          <div
            key={col.key}
            style={
              !cardView
                ? {
                    width: col.baseWidth || "150px",
                    minWidth:
                      col.minWidth || (col.maxWidth ? undefined : "150px"),
                    maxWidth: col.maxWidth,
                    overflow: "hidden",
                  }
                : undefined
            }
            className={`${
              !cardView ? "tw-grow tw-py-3 tw-pr-6 last:tw-pr-0 tw-text-sm" : ""
            } ${
              !cardView && col.align && col.align === "right"
                ? "tw-text-right"
                : ""
            }`}
          >
            {cardView && renderValue(mutadedItem, col.key)?.length === 0
              ? "-"
              : renderValue(mutadedItem, col.key)}
          </div>
        );
      })}

      {sortable && (
        <DragHandle
          key={item.item_key}
          nr={itemIndex}
          cardView={cardView || false}
        />
      )}

      {!mini && !!itemActionsList.length && (
        <div
          key="actions"
          className={`cs-tablelist-itemaction-btn-tw-helper tw-absolute tw-px-4 tw-top-2 tw-right-0 tw-text-cs-shade-500 ${
            !cardView ? "tw-bg-gradient-to-l" : ""
          } group-hover:tw-from-cs-shade-50 group-hover:tw-via-cs-shade-50 ${
            item.item_active
              ? "tw-from-cs-shade-50 tw-via-cs-shade-50"
              : "tw-from-cs-shade-white tw-via-cs-shade-white"
          }`}
        >
          <Dropdown
            toggleBtnProps={{ customStyle: "tw-text-inherit tw-p-0" }}
            items={itemActionsList}
            openOriginPreference="topright"
            icon={faCog}
          />
        </div>
      )}
    </>
  );
}

export default TableListItemCols;
